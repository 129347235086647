<template>
  <div class="dui_record">
    <!-- 顶部导航 -->
    <van-nav-bar class="topnav" title="兑换记录" left-arrow @click-left="toBack" :border="false" />
    <!-- 搜索 -->
    <div class="search">
      <van-search
        v-model="searchValue"
        :left-icon="searchIcon"
        show-action
        placeholder="搜索商品名/订单号"
      >
        <template #action>
          <div @click="searchHandel">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 分类 -->
    <van-tabs
      v-model:active="active"
      line-width="15px"
      title-active-color="#0B47AC"
      @click-tab="changetab"
    >
      <van-tab title="全部" name></van-tab>
      <van-tab title="待发货" name="0"></van-tab>
      <van-tab title="已完成" name="1"></van-tab>
    </van-tabs>
    <!-- 商品 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="goodsList">
        <van-list
          v-model:loading="loading"
          class="article1"
          :finished="finished"
          :finished-text="orderList.length == 0 ? '' : '没有更多了'"
          @load="getOrderList"
        >
          <div class="goods_item" v-for="(item,index) in orderList" :key="index">
            <div class="top">
              <div class="status">{{item.status==0?'待发货':'已完成'}}</div>
              <div class="time">{{item.created_at}}</div>
            </div>
            <div class="bottom" @click="toDetail(item.id)">
              <div class="pic">
                <!-- <img src="../../../public/statics/images/index/1.jpg" alt /> -->
                <img :src="adddom(item.goods.thumb)" alt />
              </div>
              <div class="right">
                <div class="title">{{item.goods.title}}</div>
                <div class="number">
                  <div class="ji">{{item.score}}积分</div>
                  <div class="num">x1</div>
                </div>
              </div>
            </div>
            <div class="btn clearfix" v-if="!item.address && item.goods.cate!=2">
              <div class="add f-r" @click="toaddress(item.id)">填写地址</div>
            </div>
          </div>
        </van-list>
        <van-empty v-if="!loading && orderList.length == 0" description="暂无数据" />
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      searchValue: "", //搜索内容
      searchIcon: require("../../../public/statics/images/task/search.png"),
      active: "", //状态
      pages: {
        page: 1,
        size: 10
      },
      orderList: [], //订单列表
      refreshing: false, //下拉刷新
      loading: false,
      finished: false
    };
  },
  created() {
    // this.getOrderList(); //订单列表
  },
  methods: {
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    searchHandel () {
      let that = this
      that.loading = true;
      that.orderList = [];
      that.finished = false;
      that.pages.page = 1;
      that.getOrderList();
    },
    changetab() {
      var that = this;
      that.refreshing = false;
      that.loading = true;
      that.orderList = [];
      that.finished = false;
      that.pages.page = 1;
      that.getOrderList();
    },
    onRefresh() {
      let that = this;
      that.refreshing = false;
      that.orderList = [];
      that.loading = true;
      that.finished = false;
      that.pages.page = 1;
      that.getOrderList();
    },
    toBack() {
      this.$router.go(-1);
    },
    getOrderList() {
      var that = this;
      var params = {
        token: Cookies.get("spToken"),
        status: that.active,
        key: that.searchValue,
        page: that.pages.page,
        size: that.pages.size
      };
      that.$api.integral.orderList(params).then(res => {
        if (res.code == 200) {
          // that.orderList = res.result;
          that.orderList = that.orderList.concat(res.result);
          that.pages.page++;
          // 加载状态结束
          that.loading = false;
          if (res.result.length < 1) {
            that.finished = true;
          }
        }
      });
    },
    toDetail(id) {
      var that = this;
      that.$router.push({
        path: "/dui_detail",
        query: {
          id: id
        }
      });
    },
    // 点击填写地址
    toaddress(id) {
      var that = this;
      that.$router.push({
        path: "address",
        query: {
          orderid: id
        }
      });
    }
  }
};
</script>

<style>
</style>